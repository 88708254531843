
import { Component, Vue, Prop } from 'vue-property-decorator';
import ContactForm from '@/components/contacts/ContactForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import { cloneDeep } from 'lodash';
import ValidationErrorNotification from '@/components/shared/ValidationErrorNotification.vue';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    ContactForm,
    ValidationErrorNotification,
  },
})
export default class ContactEdit extends mixins(PendingChangesGuard, RoleGuard) {
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  public value: Contact = null;
  public initialValue: Contact = null;
  public valueLoading: boolean;
  public error = '';

  private created() {
    this.valueLoading = true;
    this.$store
      .dispatch('contact/get', this.id)
      .then(contact => {
        this.value = cloneDeep(contact);
        this.initialValue = cloneDeep(contact);
        this.valueLoading = false;
      })
      .catch(e => (this.error = e));
  }

  public revert() {
    this.value = cloneDeep(this.initialValue);
  }

  public save() {
    this.$store
      .dispatch('contact/update', this.value)
      .then(updatedValue => {
        this.value = updatedValue;
        this.initialValue = cloneDeep(updatedValue);
        this.$toast.success(this.$t('contacts.edit.save-success'));
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('contacts.edit.save-error'));
      });
  }
}
